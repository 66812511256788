const EmpresaContenido = () => {
  return (
    <section className="container text-center">
      <div className="">
        <h1 className="my-5 text-center">¿Quiénes somos?</h1>
        <div className="d-flex justify-content-center">
          <hr style={{ width: "200px", color: "#d02323" }} />
        </div>
        <h5 className="my-5">
          Somos una empresa familiar dedicada a la comercialización de repuestos
          originales y de alta calidad, aplicables a las primeras marcas como
          <b> FORD - VOLKSWAGEN - IVECO</b> en la parte de pesados, medianos y
          utilitarios en todas sus líneas. Somos Concesionario Oficial de la
          reconocida marca Cummins, empresa prestigiosa proveedora de motores de
          las principales automotrices del mundo, en los rubros vehicular,
          agrícola e industrial. Realizamos servicios mecánicos, ofreciendo a
          nuestros clientes la reparación integral de motores Cummins, con
          repuestos genuinos y la mejor asesoría técnica, brindando soluciones a
          los requerimientos más exigentes del mercado. Nuestros técnicos son
          certificados en reparaciones y mantenimiento de motores Cummins con
          las más altas exigencias de calidad y conocimiento de la marca.
        </h5>
        <h5 className="my-2 ">
          ● Contamos con más de 30 años de experiencia y trayectoria en el
          mercado.
        </h5>
        <h5 className="my-2">
          ● Contamos con el más amplio stock de repuestos de calidad superior,
          garantizando la plena satisfacción de nuestros clientes.
        </h5>
        <h5 className="my-2">
          ● Contamos con el mejor equipo de ventas buscando siempre asesorar y
          solucionar las necesidades de nuestros clientes.
        </h5>
      </div>

      <div className="">
        <h1 className="my-5 text-center">Visión</h1>
        <div className="d-flex justify-content-center">
          <hr style={{ width: "200px", color: "#d02323" }} />
        </div>
        <h5 className="my-2">
          1- Consolidarnos como empresa líder en nuestra región en la provisión
          de repuestos de calidad, para las líneas de transporte Ford, VW e
          Iveco.
        </h5>
        <h5 className="my-2">
          2- Ser dignos representantes de la marca Cummins, no tan solo en la
          venta de repuestos, sino, en la excelencia del servicio.
        </h5>
        <h5 className="my-2">
          3- Ser reconocidos por la calidad humana y profesional de nuestra
          gente, basados en nuestra trayectoria y permanente capacitación, por
          nuestros clientes y proveedores.
        </h5>
      </div>
      <div className="">
        <h1 className="my-5 text-center">Misión</h1>
        <div className="d-flex justify-content-center">
          <hr style={{ width: "200px", color: "#d02323" }} />
        </div>
        <h5 className="my-2">
          Brindar plena satisfacción a nuestros clientes mediante:
        </h5>
        <h5 className="my-2">
          ⮚ Optima provisión de repuestos, filtros y lubricantes, dando rápidas
          repuestas a sus necesidades.
        </h5>
        <h5 className="my-2">
          ⮚ Realizar servicios Mecánicos - Electrónicos de mantenimiento
          preventivo y reparaciones de alta calidad.
        </h5>
        <h5 className="mt-2 mb-5">
          ⮚ Contribuir al desarrollo de las personas, buscando una relación
          duradera con nuestros clientes y colaboradores.
        </h5>
      </div>
    </section>
  );
};

export default EmpresaContenido;
