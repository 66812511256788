import BannerContacto from "../components/BannerContacto";
import ContactoContenido from "../components/ContactoContenido";

const Contacto = () => {
  return (
    <div>
      <BannerContacto />
      <ContactoContenido />
    </div>
  );
};

export default Contacto;
