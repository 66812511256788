import BannerEmpresa from "../components/BannerEmpresa";
import EmpresaContenido from "../components/EmpresaContenido";

const Empresa = () => {
  return (
    <div>
      <BannerEmpresa />
      <EmpresaContenido />
    </div>
  );
};

export default Empresa;
