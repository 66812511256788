import BannerServicios from "../components/BannerServicios";
import ServiciosContenido from "../components/ServiciosContenido";

const Servicios = () => {
  return (
    <div>
      <BannerServicios />
      <ServiciosContenido />
    </div>
  );
};

export default Servicios;
