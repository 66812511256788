// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import brand from "../assets/img/1.jpeg";
import brand2 from "../assets/img/2.jpeg";
import brand3 from "../assets/img/3.jpeg";
import brand4 from "../assets/img/4.jpeg";
import brand5 from "../assets/img/5.jpeg";
import brand6 from "../assets/img/6.jpeg";
import brand7 from "../assets/img/7.jpeg";
import brand8 from "../assets/img/8.png";
import brand10 from "../assets/img/10.jpeg";
import brand11 from "../assets/img/11.png";
import brand12 from "../assets/img/12.jpeg";
import brand13 from "../assets/img/13.jpeg";
import brand15 from "../assets/img/15.png";
import brand16 from "../assets/img/16.jpeg";
import brand17 from "../assets/img/17.png";
import brand18 from "../assets/img/18.png";
import brand19 from "../assets/img/19.png";
import brand20 from "../assets/img/20.png";

// import required modules
import {
  Autoplay,
  EffectCoverflow,
  Navigation,
  Pagination,
} from "swiper/modules";

export default function Brands({ title }) {
  return (
    <>
      <div className="text-center ">
        <h1 className=" pt-5">{title}</h1>
      </div>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, EffectCoverflow, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="centrado ">
            <img className="brands-width" src={brand} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="centrado ">
            <img className="brands-width" src={brand2} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="centrado ">
            <img className="brands-width" src={brand3} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="centrado ">
            <img className="brands-width" src={brand4} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="centrado ">
            <img className="brands-width" src={brand5} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="centrado ">
            <img className="brands-width" src={brand6} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="centrado ">
            <img className="brands-width" src={brand7} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="centrado ">
            <img className="brands-width" src={brand8} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="centrado ">
            <img className="brands-width" src={brand10} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="centrado ">
            <img className="brands-width" src={brand11} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="centrado ">
            <img className="brands-width" src={brand12} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="centrado ">
            <img className="brands-width" src={brand13} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="centrado ">
            <img className="brands-width" src={brand15} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="centrado ">
            <img className="brands-width" src={brand16} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="centrado ">
            <img className="brands-width" src={brand17} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="centrado ">
            <img className="brands-width" src={brand18} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="centrado ">
            <img className="brands-width" src={brand19} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="centrado ">
            <img className="brands-width" src={brand20} />
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
