import "./App.css";
import { HashRouter, Route, Routes } from "react-router-dom";
import Home from "./views/Home";
import Empresa from "./views/Empresa";
import Productos from "./views/Productos";
import Servicios from "./views/Servicios";
import Contacto from "./views/Contacto";
import NavReact from "./components/common/NavReact";
import Footer from "./components/common/Footer";
import Wsp from "./components/common/Wsp";
import ScrollToTop from "./components/common/ScrollToTop";

function App() {
  return (
    <div className="d-flex flex-column min-vh-100 ">
      <HashRouter>
        <NavReact />
        <Wsp />
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/empresa" element={<Empresa />} />
          <Route exact path="/productos" element={<Productos />} />
          <Route exact path="/servicios" element={<Servicios />} />
          <Route exact path="/contacto" element={<Contacto />} />
        </Routes>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
